import {
  HasuraDataTableColumnDef,
  DataTableEx,
  DataTableExProps,
  DataTableExRef,
  FormInput,
  FileInput,
  CheckboxInput,
  Grid,
  FormGetter,
  NotificationsContext,
  FormSetter,
  TranslationInput,
} from '@kirz/mui-admin';
import { Button } from '@mui/material';
import React, {
  forwardRef,
  Ref,
  useContext,
  useImperativeHandle,
  useMemo,
  useRef,
} from 'react';

import { api } from 'services/api';

export const VideoGroupItemsTable = forwardRef(
  (
    props: Partial<DataTableExProps> & {
      id: string;
      filterColumns?: (
        columns: HasuraDataTableColumnDef[],
      ) => HasuraDataTableColumnDef[];
    },
    ref: Ref<DataTableExRef>,
  ) => {
    const { filterColumns, ...rest } = props;
    const tableRef = useRef<DataTableExRef>(null);

    const { showAlert } = useContext(NotificationsContext);
    useImperativeHandle(ref, () => tableRef.current!, []);

    const columns = useMemo<HasuraDataTableColumnDef[]>(
      () =>
        (filterColumns || ((x: HasuraDataTableColumnDef[]) => x))([
          {
            headerName: 'Youtube URL',
            field: 'youtubeUrl',
            flex: 1,
            minWidth: 100,
          },
          {
            field: 'thumbnailImageId',
            headerName: 'Thumbnail',
            type: 'file',
            hideText: true,
            width: 80,
            sortable: false,
          },
          {
            field: 'isActive',
            headerName: 'Active',
            type: 'boolean',
            sortable: false,
          },
        ]),
      [filterColumns],
    );

    return (
      <DataTableEx
        {...rest}
        ref={tableRef}
        source="videoGroupItem"
        columns={columns}
        persistStateMode="query"
        sortBy={rest.sortBy ?? { field: 'createdAt', sort: 'desc' }}
        formTitle={() => 'New video'}
        formDialogProps={{
          ...rest.formDialogProps,
          autoFocus: false,
          formProps: {
            ...rest.formDialogProps?.formProps,
            shouldUnregister: false,
            defaultValues: {
              ...rest.formDialogProps?.formProps?.defaultValues,
              isActive: true,
            },
          },
          formSubmitterProps: {
            ...rest.formDialogProps?.formSubmitterProps,
            preSubmit({ _new, ...item }) {
              return item;
            },
          },
        }}
        searchFilter={{
          ...rest.searchFilter,
          inputProps: {
            placeholder: 'Search by url',
          },
          filter: (search) => ({
            _or: search.flatMap((str) => [
              { youtubeUrl: { _ilike: `%${str}%` } },
            ]),
          }),
        }}
        customFilter={{
          filters: [
            {
              type: 'boolean',
              name: 'Active',
              field: 'isActive',
            },
          ],
        }}
      >
        <TranslationInput
          name="name"
          label="Name"
          required
          gridCols={{ md: 6, lg: 6, xl: 6 }}
          xs={12}
        />
        <Grid sx={{ display: 'flex', alignItems: 'center' }} xs={12}>
          <FormInput
            name="youtubeUrl"
            label="Youtube URL"
            required
            placeholder="https://www.youtube.com/watch?v=video_id"
            grid={false}
            sx={{ flex: 1 }}
          />
          <FormSetter
            render={(setValue) => (
              <FormGetter
                names={['youtubeUrl']}
                render={(x) => (
                  <Button
                    sx={{ ml: 2 }}
                    variant="outlined"
                    disabled={!x.youtubeUrl}
                    onClick={async () => {
                      try {
                        const url = new URL(x.youtubeUrl);
                        console.log(url, x);
                        const id = url.searchParams.get('v');

                        const result = await api.client.post(
                          `/youtube/${id}/upload-thumbnail`,
                        );
                        console.log(result.data);
                        setValue('thumbnailImageId', result.data.id);
                        showAlert(`Thumbnail extracted`, 'success');
                      } catch {
                        showAlert(`Can't extract the thumbnail`, 'error');
                      }
                    }}
                  >
                    Extract thumbnail
                  </Button>
                )}
              />
            )}
          />
        </Grid>

        <FileInput
          name="thumbnailImageId"
          label="Thumbnail image"
          required
          dropzoneProps={{
            accept: {
              'image/png': ['.png'],
              'image/jpeg': ['.jpg', '.jpeg'],
            },
          }}
        />

        <CheckboxInput name="isActive" label="Active" sm="auto" />
      </DataTableEx>
    );
  },
);
