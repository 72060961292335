import { BasePageLayout } from '@kirz/mui-admin';
import React from 'react';

import AnalyticsDashboard from 'components/AnalyticsView';

export function Analytics() {
  return (
    <BasePageLayout title="Analytics">
      <AnalyticsDashboard />
    </BasePageLayout>
  );
}
