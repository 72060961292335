import {
  HasuraDataTableColumnDef,
  DataTableEx,
  DataTableExProps,
  DataTableExRef,
  AutocompleteInput,
  HiddenInput,
} from '@kirz/mui-admin';
import React, {
  forwardRef,
  Ref,
  useImperativeHandle,
  useMemo,
  useRef,
} from 'react';

export const ActivityGroupUsersTable = forwardRef(
  (
    props: Partial<DataTableExProps> & {
      id: string;
      filterColumns?: (
        columns: HasuraDataTableColumnDef[],
      ) => HasuraDataTableColumnDef[];
    },
    ref: Ref<DataTableExRef>,
  ) => {
    const { filterColumns, ...rest } = props;
    const tableRef = useRef<DataTableExRef>(null);

    useImperativeHandle(ref, () => tableRef.current!, []);

    const columns = useMemo<HasuraDataTableColumnDef[]>(
      () =>
        (filterColumns || ((x: HasuraDataTableColumnDef[]) => x))([
          {
            field: 'user { *email* }',
            headerName: 'User',
            type: 'relationship',
          },
          {
            field: 'createdAt',
            headerName: 'Created at',
            type: 'date',
            width: 100,
          },
        ]),
      [filterColumns],
    );

    return (
      <DataTableEx
        {...rest}
        ref={tableRef}
        title="Users"
        source="userActivityGroup"
        columns={columns}
        persistStateMode="query"
        sortBy={{ field: 'createdAt', sort: 'desc' }}
        formTitle={() => 'New user'}
      >
        <AutocompleteInput
          name="userId"
          source="user"
          mode="hasura"
          selection="id email"
          label="User"
          itemText="email"
          itemValue="id"
          required
          fetchAll={false}
        />
        <HiddenInput name="activityGroupId" />
      </DataTableEx>
    );
  },
);
