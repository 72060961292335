import {
  HasuraDataTableColumnDef,
  TablePageLayout,
  DataTableEx,
  DataTableExRef,
  TranslationInput,
} from '@kirz/mui-admin';
import AddIcon from '@mui/icons-material/Add';
import { Button } from '@mui/material';
import React, { useMemo, useRef } from 'react';

export function ActivityGroups() {
  const tableRef = useRef<DataTableExRef>(null);

  const columns = useMemo<HasuraDataTableColumnDef[]>(
    () => [
      {
        field: 'id',
        headerName: 'ID',
        width: 100,
      },
      {
        field: 'name',
        headerName: 'Name',
        type: 'translation',
        flex: 1,
      },
      {
        field: 'usersAggregate { aggregate { *count* } }',
        type: 'relationship',
        headerName: 'Number of users',
        flex: 1,
        fetchRemoved: false,
      },
      {
        field: 'categoriesAggregate { aggregate { *count* } }',
        type: 'relationship',
        headerName: 'Number of categories',
        flex: 1,
        fetchRemoved: false,
      },
      {
        field: 'isActive',
        headerName: 'Active',
        type: 'boolean',
        sortable: false,
      },
    ],
    [],
  );

  return (
    <TablePageLayout
      title="Challenge/Group"
      actionContent={
        <Button
          sx={{ ml: 'auto' }}
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => {
            tableRef.current?.openFormDialog();
          }}
        >
          Add new
        </Button>
      }
    >
      <DataTableEx
        id="activity-groups-table"
        ref={tableRef}
        source="activityGroup"
        columns={columns}
        sortBy={{ field: 'id', sort: 'asc' }}
        editPageUrl="/activity-groups"
        persistStateMode="query"
        formDialogProps={{
          autoFocus: false,
          formProps: {
            defaultValues: {
              isActive: false,
            },
          },
        }}
        searchFilter={{
          inputProps: {
            placeholder: 'Search by name',
          },
          filter: (search) => ({
            _or: search.flatMap((str) => [
              { name: { _cast: { String: { _ilike: `%${str}%` } } } },
            ]),
          }),
        }}
        customFilter={{
          filters: [
            {
              type: 'boolean',
              name: 'Is active',
              field: 'isActive',
            },
          ],
        }}
        formTitle={(isNew) => (isNew ? 'New group' : 'Edit group')}
      >
        <TranslationInput
          name="name"
          label="Name"
          required
          xs={12}
          gridCols={{ xs: 12, md: 12, lg: 12, xl: 12 }}
        />
      </DataTableEx>
    </TablePageLayout>
  );
}
