import { ConfigurationContext } from '@kirz/mui-admin';
import {
  Box,
  Card,
  CardContent,
  Typography,
  Grid,
  CircularProgress,
} from '@mui/material';
import dayjs from 'dayjs';
import React, { useContext, useEffect, useState } from 'react';

const AnalyticsDashboard = () => {
  const { hasura } = useContext(ConfigurationContext);

  const [data, setData] = useState({
    downloads: { today: 0, week: 0, month: 0 },
    registrations: { today: 0, week: 0, month: 0 },
    activeUsers: { today: 0, week: 0, month: 0 },
    subscriptionConversion: 0,
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await hasura.request({
          type: 'custom',
          query: `
            query FetchAnalytics($now: timestamptz!, $day: timestamptz!, $week: timestamptz!, $month: timestamptz!) {
              dayInstalls: installAggregate(where: { _and: [ {createdAt: {_gte: $day}}, {createdAt: {_lt: $now}} ] }) {
                  aggregate {count}
              }
              weekInstalls: installAggregate(where: { _and: [ {createdAt: {_gte: $week}}, {createdAt: {_lt: $now}} ] }) {
                  aggregate {count}
              }
              monthInstalls: installAggregate(where: { _and: [ {createdAt: {_gte: $month}}, {createdAt: {_lt: $now}} ] }) {
                  aggregate {count}
              }

              daySignups: userAggregate(where: { _and: [ {createdAt: {_gte: $day}}, {createdAt: {_lt: $now}} ] }) {
                  aggregate {count}
              }
              weekSignups: userAggregate(where: { _and: [ {createdAt: {_gte: $week}}, {createdAt: {_lt: $now}} ] }) {
                  aggregate {count}
              }
              monthSignups: userAggregate(where: { _and: [ {createdAt: {_gte: $month}}, {createdAt: {_lt: $now}} ] }) {
                  aggregate {count}
              }

              dayActive: userAggregate(where: { _and: [ {lastLoggedAt: {_gte: $day}}, {lastLoggedAt: {_lt: $now}} ] }) {
                  aggregate {count}
              }
              weekActive: userAggregate(where: { _and: [ {lastLoggedAt: {_gte: $week}}, {lastLoggedAt: {_lt: $now}} ] }) {
                  aggregate {count}
              }
              monthActive: userAggregate(where: { _and: [ {lastLoggedAt: {_gte: $month}}, {lastLoggedAt: {_lt: $now}} ] }) {
                  aggregate {count}
              }

              users: user(orderBy:{createdAt: DESC}, limit: 100) {
                  id
                  subscription
              }
            }
          `,
          variables: {
            now: new Date().toISOString(),
            day: dayjs().add(-1, 'day').toISOString(),
            week: dayjs().add(-7, 'day').toISOString(),
            month: dayjs().add(-1, 'month').toISOString(),
          },
        });
        setData({
          downloads: {
            today: response.dayInstalls.aggregate.count,
            week: response.weekInstalls.aggregate.count,
            month: response.monthInstalls.aggregate.count,
          },
          registrations: {
            today: response.daySignups.aggregate.count,
            week: response.weekSignups.aggregate.count,
            month: response.monthSignups.aggregate.count,
          },
          activeUsers: {
            today: response.dayActive.aggregate.count,
            week: response.weekActive.aggregate.count,
            month: response.monthActive.aggregate.count,
          },
          subscriptionConversion: Math.round(
            (response.users.filter((user: any) => user.subscription).length /
              response.users.length) *
              100,
          ),
        });
        setLoading(false);
      } catch (error) {
        console.error('Error fetching analytics data:', error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const renderStatCard = (
    title: string,
    stats: {
      today: number;
      week: number;
      month: number;
    },
  ) => (
    <Card>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          {title}
        </Typography>
        <Typography variant="body1">Today: {stats.today}</Typography>
        <Typography variant="body1">This Week: {stats.week}</Typography>
        <Typography variant="body1">This Month: {stats.month}</Typography>
      </CardContent>
    </Card>
  );

  return (
    <Box p={3}>
      {loading ? (
        <CircularProgress />
      ) : (
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            {renderStatCard('Downloads', data.downloads)}
          </Grid>
          <Grid item xs={12} md={4}>
            {renderStatCard('Registrations', data.registrations)}
          </Grid>
          <Grid item xs={12} md={4}>
            {renderStatCard('Active Users', data.activeUsers)}
          </Grid>
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Conversion of Recent 100 Users Switching to Subscription
                </Typography>
                <Typography variant="h4">
                  {data.subscriptionConversion}%
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default AnalyticsDashboard;
