import {
  AutocompleteInput,
  CheckboxInput,
  ColorInput,
  ConditionalInput,
  FileInput,
  FormGetter,
  FormInput,
  FormPageLayout,
  Grid,
  LanguagesContext,
  TranslationInput,
} from '@kirz/mui-admin';
import { Box } from '@mui/material';
import { FlagCheckered } from 'mdi-material-ui';
import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';

import { ActivityGroupCategoriesTable } from 'components/ActivityGroupCategoriesTable';
import { ActivityGroupNotificationsTable } from 'components/ActivityGroupNotificationsTable';
import { ActivityGroupUsersTable } from 'components/ActivityGroupUsersTable';
import { FontFamilies, TextAlignments, TextPositions } from 'constants/other';

export function ActivityGroup() {
  const routeParams = useParams() as Record<string, string>;
  const groupId = parseInt(routeParams.id, 10);
  const { defaultLanguageId } = useContext(LanguagesContext);

  return (
    <FormPageLayout
      source="activityGroup"
      breadcrumbs={[
        {
          text: 'Challenge/Group',
          icon: FlagCheckered,
          href: '/activity-groups',
        },
        (item) => ({
          text: `#${item.id} ${item.name?.[defaultLanguageId]}`,
          copyOnClick: true,
        }),
      ]}
      breadcrumbsDeps={['name']}
      defaultRoute="/activity-groups"
    >
      <Grid container alignItems="flex-start">
        <TranslationInput
          name="name"
          label="Name"
          required
          xs={12}
          gridCols={{ xs: 12, md: 12, lg: 12, xl: 12 }}
        />

        <ConditionalInput
          deps={['showOnMainScreen']}
          variants={[
            {
              if: (x) => x.showOnMainScreen,
              input: (
                <AutocompleteInput
                  name="testUserId"
                  source="user"
                  mode="hasura"
                  selection="id email"
                  label="Tester"
                  itemText="email"
                  itemValue="id"
                  md={4}
                  fetchAll={false}
                />
              ),
            },
          ]}
        />

        <FormGetter
          names={['fontFamily']}
          render={({ fontFamily }) => (
            <ConditionalInput
              deps={['showOnMainScreen']}
              variants={[
                {
                  if: (x) => x.showOnMainScreen,
                  input: (
                    <AutocompleteInput
                      name="fontFamily"
                      label="Font"
                      options={FontFamilies}
                      itemText="text"
                      itemValue="value"
                      md={4}
                      required
                      sx={{
                        '& input': {
                          fontFamily:
                            FontFamilies.find((x) => x.value === fontFamily)
                              ?.text +
                            (fontFamily === 'Salsa'
                              ? ', cursive'
                              : fontFamily === 'UbuntuMono'
                              ? ', monospace'
                              : ', sans-serif'),
                        },
                      }}
                      renderOption={(props, option, state) => (
                        <Box
                          {...props}
                          component="li"
                          sx={{
                            fontFamily:
                              FontFamilies.find((x) => x.value === option.value)
                                ?.text +
                              (option.value === 'Salsa'
                                ? ', cursive'
                                : option.value === 'UbuntuMono'
                                ? ', monospace'
                                : ', sans-serif'),
                          }}
                        >
                          {option.text}
                        </Box>
                      )}
                    />
                  ),
                },
              ]}
            />
          )}
        />

        <CheckboxInput name="isActive" label="Enabled?" sm="auto" md={2} />
        <CheckboxInput
          name="showOnMainScreen"
          label="Is a Challenge?"
          sm="auto"
          md={2}
        />

        <ConditionalInput
          deps={['showOnMainScreen']}
          variants={[
            {
              if: (x) => x.showOnMainScreen,
              input: (
                <AutocompleteInput
                  name="textAlignment"
                  label="Text alignment"
                  options={TextAlignments}
                  itemText="text"
                  itemValue="value"
                  md={4}
                  required
                />
              ),
            },
          ]}
        />

        <ConditionalInput
          deps={['showOnMainScreen']}
          variants={[
            {
              if: (x) => x.showOnMainScreen,
              input: (
                <AutocompleteInput
                  name="textPosition"
                  label="Text position"
                  options={TextPositions}
                  itemText="text"
                  itemValue="value"
                  md={4}
                  required
                />
              ),
            },
          ]}
        />

        <ConditionalInput
          deps={['showOnMainScreen']}
          variants={[
            {
              if: (x) => x.showOnMainScreen,
              input: (
                <ColorInput
                  name="textColor"
                  label="Text color"
                  required
                  md={2}
                />
              ),
            },
          ]}
        />

        <ConditionalInput
          deps={['showOnMainScreen']}
          variants={[
            {
              if: (x) => x.showOnMainScreen,
              input: (
                <FormInput
                  label="Font size"
                  name="textSize"
                  type="number"
                  required
                  md={2}
                />
              ),
            },
          ]}
        />

        <ConditionalInput
          deps={['showOnMainScreen']}
          variants={[
            {
              if: (x) => x.showOnMainScreen,
              input: (
                <FileInput
                  name="bannerImageId"
                  label="Challenge image"
                  required
                  md={4}
                  helperText="Landscape or square image preferred"
                  dropzoneProps={{
                    accept: {
                      'image/png': ['.png'],
                      'image/jpeg': ['.jpg', '.jpeg'],
                    },
                  }}
                />
              ),
            },
          ]}
        />

        <ConditionalInput
          deps={['showOnMainScreen']}
          variants={[
            {
              if: (x) => x.showOnMainScreen,
              input: (
                <FormInput
                  name="bannerText"
                  label="Challenge text"
                  helperText="Use [counter] for number of challengers"
                  required
                  multiline
                  rows={3}
                  md={8}
                />
              ),
            },
          ]}
        />
      </Grid>
      <Grid container spacing={3} sx={{ mt: 3 }}>
        <Grid xs={12} md={6}>
          <ActivityGroupCategoriesTable
            id="activity-group-categories-table"
            sortBy={{ field: 'serialNumber', sort: 'asc' }}
            sx={{
              minHeight: 400,
              // borderColor: 'transparent',
            }}
            // inline
            selectProps={{
              filter: {
                activityGroupId: { _eq: groupId },
              },
            }}
            formDialogProps={{
              formProps: {
                defaultValues: {
                  activityGroupId: groupId,
                },
              },
            }}
          />
        </Grid>
        <Grid xs={12} md={6}>
          <ActivityGroupUsersTable
            id="activity-group-users-table"
            sortBy={{ field: 'serialNumber', sort: 'asc' }}
            sx={{
              minHeight: 400,
              // borderColor: 'transparent',
            }}
            // inline
            selectProps={{
              filter: {
                activityGroupId: { _eq: groupId },
              },
            }}
            formDialogProps={{
              formProps: {
                defaultValues: {
                  activityGroupId: groupId,
                },
              },
            }}
          />
        </Grid>
        <Grid xs={12}>
          <ActivityGroupNotificationsTable
            id="activity-group-notifications-table"
            sortBy={{ field: 'serialNumber', sort: 'asc' }}
            sx={{
              minHeight: 400,
              // borderColor: 'transparent',
            }}
            // inline
            selectProps={{
              filter: {
                activityGroupId: { _eq: groupId },
              },
            }}
            formDialogProps={{
              formProps: {
                defaultValues: {
                  activityGroupId: groupId,
                  type: 'custom',
                },
              },
            }}
          />
        </Grid>
      </Grid>
    </FormPageLayout>
  );
}
