import {
  HasuraDataTableColumnDef,
  DataTableEx,
  DataTableExProps,
  DataTableExRef,
  HiddenInput,
  FormInput,
  SelectInput,
  DateInput,
  TimeInput,
  ConditionalInput,
} from '@kirz/mui-admin';
import dayjs from 'dayjs';
import React, {
  forwardRef,
  Ref,
  useImperativeHandle,
  useMemo,
  useRef,
} from 'react';

export const ActivityGroupNotificationsTable = forwardRef(
  (
    props: Partial<DataTableExProps> & {
      id: string;
      filterColumns?: (
        columns: HasuraDataTableColumnDef[],
      ) => HasuraDataTableColumnDef[];
    },
    ref: Ref<DataTableExRef>,
  ) => {
    const { filterColumns, ...rest } = props;
    const tableRef = useRef<DataTableExRef>(null);

    useImperativeHandle(ref, () => tableRef.current!, []);

    const columns = useMemo<HasuraDataTableColumnDef[]>(
      () =>
        (filterColumns || ((x: HasuraDataTableColumnDef[]) => x))([
          {
            field: 'title',
            headerName: 'Title',
          },
          {
            field: 'text',
            headerName: 'Text',
          },
          {
            field: 'type',
            headerName: 'Type',
          },
          {
            field: 'datetime',
            headerName: 'Time',
            type: 'dateTime',
          },
          {
            field: 'createdAt',
            headerName: 'Created at',
            type: 'date',
            width: 100,
          },
        ]),
      [filterColumns],
    );

    return (
      <DataTableEx
        {...rest}
        ref={tableRef}
        title="Notifications"
        source="activityGroupNotification"
        columns={columns}
        persistStateMode="query"
        sortBy={{ field: 'createdAt', sort: 'desc' }}
        formTitle={() => 'New category'}
        formDialogProps={{
          ...rest.formDialogProps,
          formFetcherProps: {
            onSelection(selections) {
              return [
                ...selections.filter((x) => x !== 'time' && x !== 'date'),
                'datetime',
              ];
            },
            onFetch(item) {
              return {
                ...item,
                ...(item.datetime && {
                  date: dayjs(item.datetime).format('YYYY-MM-DD'),
                  time: dayjs(item.datetime).format('HH:mm'),
                }),
              };
            },
          },
          formSubmitterProps: {
            preSubmit({ time, date, ...item }) {
              return {
                ...item,
                datetime: !date
                  ? null
                  : dayjs(
                      `${date}T${time ?? '00:00'}:00`,
                      'YYYY-MM-DDTHH:mm:ss',
                    ).toISOString(),
              };
            },
          },
        }}
      >
        <SelectInput
          name="type"
          label="Type"
          required
          items={[
            { value: 'start', text: 'Start' },
            { value: 'custom', text: 'Custom' },
          ]}
          helperText={`"start" -- will be sent when the challenge starts. "custom" -- will be sent at specific time.`}
          sx={{ mb: 2 }}
        />
        <ConditionalInput
          deps={['type']}
          variants={[
            {
              if: (x) => x.type === 'custom',
              input: (
                <DateInput
                  name="date"
                  label="Date"
                  required
                  sm={6}
                  formFetcherValueResolver={null}
                  formSubmitterValueResolver={null}
                />
              ),
            },
          ]}
        />
        <ConditionalInput
          deps={['type']}
          variants={[
            {
              if: (x) => x.type === 'custom',
              input: (
                <TimeInput
                  sm={6}
                  clearable={false}
                  name="time"
                  label="Time"
                  required
                  ampm={false}
                  formFetcherValueResolver={null}
                  formSubmitterValueResolver={null}
                />
              ),
            },
          ]}
        />
        <FormInput name="title" label="Title" required />
        <FormInput name="text" label="Text" required multiline rows={3} />

        <HiddenInput name="activityGroupId" />
      </DataTableEx>
    );
  },
);
