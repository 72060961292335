import {
  HasuraDataTableColumnDef,
  DataTableEx,
  DataTableExProps,
  DataTableExRef,
  AutocompleteInput,
  LanguagesContext,
  HiddenInput,
} from '@kirz/mui-admin';
import React, {
  forwardRef,
  Ref,
  useContext,
  useImperativeHandle,
  useMemo,
  useRef,
} from 'react';

export const ActivityGroupCategoriesTable = forwardRef(
  (
    props: Partial<DataTableExProps> & {
      id: string;
      filterColumns?: (
        columns: HasuraDataTableColumnDef[],
      ) => HasuraDataTableColumnDef[];
    },
    ref: Ref<DataTableExRef>,
  ) => {
    const { filterColumns, ...rest } = props;
    const { defaultLanguageId } = useContext(LanguagesContext);
    const tableRef = useRef<DataTableExRef>(null);

    useImperativeHandle(ref, () => tableRef.current!, []);

    const columns = useMemo<HasuraDataTableColumnDef[]>(
      () =>
        (filterColumns || ((x: HasuraDataTableColumnDef[]) => x))([
          {
            field: 'category { *name* }',
            type: 'relationship',
            headerName: 'Category',
            valueGetter({ row }) {
              return (row.category?.name[defaultLanguageId] || '').replace(
                '<br>',
                ' ',
              );
            },
          },
          {
            field: 'createdAt',
            headerName: 'Created at',
            type: 'date',
            width: 100,
          },
        ]),
      [filterColumns],
    );

    return (
      <DataTableEx
        {...rest}
        ref={tableRef}
        title="Categories"
        source="categoryActivityGroup"
        columns={columns}
        persistStateMode="query"
        sortBy={{ field: 'createdAt', sort: 'desc' }}
        formTitle={() => 'New category'}
      >
        <AutocompleteInput
          name="categoryId"
          source="category"
          mode="hasura"
          selection="id name"
          label="Category"
          itemText={(row) =>
            (row.name[defaultLanguageId] || '').replace('<br>', ' ')
          }
          itemValue="id"
          required
        />
        <HiddenInput name="activityGroupId" />
      </DataTableEx>
    );
  },
);
