import { LinkBehavior, useNavigate } from '@kirz/mui-admin';
import { Menu } from '@mui/icons-material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import SignalCellularAltRoundedIcon from '@mui/icons-material/SignalCellularAltRounded';
import {
  Box,
  Button,
  CSSObject,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListSubheader,
  Theme,
  Tooltip,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  AccountGroupOutline,
  DatabaseOutline,
  CogOutline,
  AccountMultipleOutline,
  Meditation,
  Translate,
  EmailMultipleOutline,
  GiftOutline,
  YoutubeTv,
  MessageDraw,
  CommentQuoteOutline,
  History,
  ChartMultiline,
  FlagCheckered,
} from 'mdi-material-ui';
import React, { useContext, useEffect, useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';

import { CompanyLogo } from 'components/CompanyLogo';
import { NAVIGATION_DRAWER_WIDTH, TOOLBAR_HEIGHT } from 'constants/theme';
import { UserContext } from 'contexts/UserContext';

type Props = {
  isDrawerOpened: boolean;
  setIsDrawerOpened: (value: boolean) => void;
};

type NavigationSection = {
  name: string;
  items: {
    name: string;
    icon?: React.ReactNode;
    href: string;
    if?: string | string[];
  }[];
};

export function NavigationDrawer({ isDrawerOpened, setIsDrawerOpened }: Props) {
  const { hasPermission } = useContext(UserContext);

  const [isDrawerMiniModeEnabled, setIsDrawerMiniModeEnabled] = useState(
    window.localStorage.getItem('drawer-mine-mode') === 'true',
  );
  const container =
    window !== undefined ? () => window.document.body : undefined;
  const navigate = useNavigate();

  const sections = (
    [
      {
        name: 'Content',
        items: [
          {
            name: 'Clients',
            href: '/clients',
            icon: <AccountGroupOutline />,
          },
          {
            name: 'Categories',
            href: '/categories',
            icon: <DatabaseOutline />,
          },
          {
            name: 'Meditations',
            href: '/meditations',
            icon: <Meditation />,
          },
          {
            name: 'Video Lanes',
            href: '/video-lanes',
            icon: <YoutubeTv />,
          },
          {
            name: 'Challenge/Group',
            href: '/activity-groups',
            icon: <FlagCheckered />,
          },
          {
            name: 'Quotes',
            href: '/quotes',
            icon: <CommentQuoteOutline />,
          },
          // {
          //   name: 'Music',
          //   href: '/music',
          //   icon: <Music />,
          // },
        ],
      },
      {
        name: 'Other',
        items: [
          {
            name: 'Gifts',
            href: '/gifts',
            icon: <GiftOutline />,
          },
          {
            name: 'Feedback',
            href: '/feedback',
            icon: <MessageDraw />,
          },
          {
            name: 'Analytics',
            href: '/analytics',
            icon: <ChartMultiline />,
          },
          {
            name: 'Notifications',
            href: '/notifications',
            icon: <EmailMultipleOutline />,
          },
          {
            name: 'Listening History',
            href: '/listening-history',
            icon: <History />,
          },
        ],
      },
      {
        name: 'System',
        items: [
          {
            name: 'Languages',
            href: '/languages',
            icon: <Translate />,
          },
          {
            name: 'Users',
            href: '/users',
            icon: <AccountMultipleOutline />,
          },
          {
            name: 'Settings',
            href: '/settings',
            icon: <CogOutline />,
          },
        ],
      },
    ] as NavigationSection[]
  )
    .map((menuItem) => ({
      ...menuItem,
      items: menuItem.items.filter(
        (x) =>
          !x.if ||
          (typeof x.if === 'string'
            ? hasPermission(x.if)
            : x.if.find((y) => hasPermission(y))),
      ),
    }))
    .filter((x) => x.items.length > 0);

  const content = (miniMode: boolean) => (
    <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
      <Box
        sx={{
          px: miniMode ? 0 : 1.5,
          display: 'flex',
          alignItems: 'center',
          height: TOOLBAR_HEIGHT,
          minHeight: TOOLBAR_HEIGHT,
          ...(miniMode && {
            width: '100%',
            justifyContent: 'center',
            ml: '-2px',
          }),
        }}
      >
        {!miniMode && <CompanyLogo sx={{ width: 46, opacity: 0.8, ml: 0.5 }} />}
        <IconButton
          onClick={() => {
            setIsDrawerMiniModeEnabled(!miniMode);
          }}
          sx={{
            display: { xs: 'none', lg: 'flex' },
            ml: 'auto',
            color: 'rgb(209, 213,219)',
            mr: -1,
            ...(miniMode && {
              ml: 0,
              mr: 0,
            }),
          }}
        >
          {!miniMode ? (
            <ChevronLeftIcon sx={{ opacity: 0.8, fontSize: '2rem' }} />
          ) : (
            <Menu />
          )}
        </IconButton>
      </Box>
      <Styled.Divider sx={{ mt: 0, mb: 0 }} />
      <Box
        component={Scrollbars}
        autoHide
        sx={{
          '& div': {
            display: 'flex',
            flexDirection: 'column',
          },
          '& div:nth-of-type(3) > div': {
            backgroundColor: 'rgba(255, 255, 255, 0.2) !important',
          },
        }}
      >
        {sections.map((section) => (
          <Styled.List
            disablePadding
            key={section.name}
            sx={{
              pb: 1,
              ...(miniMode && {
                pb: 2,
              }),
            }}
            subheader={
              <Styled.ListSubheader disableGutters disableSticky>
                {!miniMode && section.name}
              </Styled.ListSubheader>
            }
          >
            {section.items.map((item) => (
              <Tooltip
                key={item.name}
                title={miniMode ? item.name : ''}
                placement="right"
              >
                <Styled.ListItem
                  disableGutters
                  sx={{
                    ...(miniMode && {
                      pl: 0,
                      m: 0,
                      p: 0,
                      minWidth: 0,
                    }),
                  }}
                >
                  <Styled.Button
                    LinkComponent={LinkBehavior}
                    startIcon={item.icon || <SignalCellularAltRoundedIcon />}
                    href={item.href}
                    onClick={async (e) => {
                      const openInNewTab = e.shiftKey || e.ctrlKey || e.metaKey;
                      if (openInNewTab) {
                        return;
                      }

                      e.preventDefault();

                      navigate(item.href);

                      setIsDrawerOpened(false);
                    }}
                    sx={{
                      paddingLeft: '14px',
                      ...(miniMode && {
                        paddingLeft: '14px',
                        minWidth: 0,
                        px: 0,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        '& .MuiButton-startIcon': {
                          marginRight: 0,
                        },
                      }),
                    }}
                  >
                    {!miniMode && item.name}
                  </Styled.Button>
                </Styled.ListItem>
              </Tooltip>
            ))}
          </Styled.List>
        ))}
      </Box>
    </Box>
  );

  useEffect(() => {
    window.localStorage.setItem(
      'drawer-mine-mode',
      isDrawerMiniModeEnabled.toString(),
    );
  }, [isDrawerMiniModeEnabled]);

  return (
    <>
      <Box sx={{ height: '100vh' }} />
      <Drawer
        container={container}
        variant="temporary"
        open={isDrawerOpened}
        onClose={() => setIsDrawerOpened(!isDrawerOpened)}
        ModalProps={{
          keepMounted: true,
        }}
        PaperProps={{
          className: 'no-scrollbar',
        }}
        sx={{
          zIndex: 1300,
          display: { xs: 'block', lg: 'none' },
          '& .MuiDrawer-paper': {
            backgroundColor: 'brand.main',
            boxSizing: 'border-box',
            width: NAVIGATION_DRAWER_WIDTH,
          },
        }}
      >
        {content(false)}
      </Drawer>
      <Styled.Drawer
        variant="permanent"
        // PaperProps={{
        //   className: 'no-scrollbar',
        // }}
        sx={{
          height: '100vh',
          display: { xs: 'none', lg: 'block' },
          '& .MuiDrawer-paper': {
            backgroundColor: 'brand.main',
            boxSizing: 'border-box',
          },
          ...(isDrawerMiniModeEnabled && {
            width: '49px',
          }),
        }}
        PaperProps={{
          sx: {
            ...(isDrawerMiniModeEnabled && {
              width: '49px !important',
            }),
          },
        }}
        open={!isDrawerMiniModeEnabled}
      >
        {content(isDrawerMiniModeEnabled)}
      </Styled.Drawer>
    </>
  );
}

// Styles
const openedMixin = (theme: Theme): CSSObject => ({
  width: NAVIGATION_DRAWER_WIDTH.lg,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: '68px',
});

const Styled = {
  Divider: styled(Divider)(() => ({
    margin: '24px 0px',
    flexShrink: '0',
    borderWidth: '0px 0px thin',
    borderStyle: 'solid',
    borderColor: 'rgb(45, 55, 72)',
  })),
  List: styled(List)(() => ({
    listStyle: 'none',
    margin: '2px 0px 0px',
    padding: '0px 0px 8px',
    position: 'relative',
  })),
  ListItem: styled(ListItem)(() => ({
    justifyContent: 'flex-start',
    alignItems: 'center',
    position: 'relative',
    textDecoration: 'none',
    width: '100%',
    boxSizing: 'border-box',
    textAlign: 'left',
    display: 'flex',
    marginBottom: '4px',
    // padding: '0px 16px',
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: '8px',
    paddingRight: '8px',
  })),
  ListSubheader: styled(ListSubheader)(() => ({
    boxSizing: 'border-box',
    listStyle: 'none',
    fontFamily:
      'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
    color: 'rgb(107, 114, 128)',
    fontSize: '0.75rem',
    fontWeight: '700',
    lineHeight: '2.5',
    marginLeft: '16px',
    textTransform: 'uppercase',
  })),
  Button: styled(Button)(() => ({
    display: 'inline-flex',
    WebkitBoxAlign: 'center',
    alignItems: 'center',
    position: 'relative',
    boxSizing: 'border-box',
    WebkitTapHighlightColor: 'transparent',
    backgroundColor: 'transparent',
    outline: '0px',
    border: '0px',
    margin: '0px',
    cursor: 'pointer',
    userSelect: 'none',
    verticalAlign: 'middle',
    appearance: 'none',
    textDecoration: 'none',
    fontWeight: '600',
    fontFamily:
      'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
    fontSize: '0.875rem',
    lineHeight: '1.75',
    minWidth: '64px',
    transition:
      'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    boxShadow: 'none',
    padding: '9px 24px',
    // paddingLeft: '14px',
    borderRadius: '8px',
    color: 'rgb(209, 213, 219)',
    WebkitBoxPack: 'start',
    justifyContent: 'flex-start',
    textAlign: 'left',
    textTransform: 'none',
    width: '100%',
    '&:hover, &.active': {
      backgroundColor: 'rgba(255, 255, 255, 0.08)',
    },
    '&.active': {
      color: 'rgb(16, 185, 129)',
    },
  })) as typeof Button,
  Drawer: styled(Drawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
      width: NAVIGATION_DRAWER_WIDTH.lg,
      flexShrink: 0,
      whiteSpace: 'nowrap',
      boxSizing: 'border-box',
      ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
      }),
      ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
      }),
    }),
  ),
};
