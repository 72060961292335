import {
  DataTable,
  FormInput,
  DataTableRef,
  HasuraDataTableColumnDef,
  TablePageLayout,
  FormDialog,
  ConfigurationContext,
  NotificationsContext,
} from '@kirz/mui-admin';
import { Button, Stack } from '@mui/material';
import { AccountArrowRight, FileExcel } from 'mdi-material-ui';
import React, { useContext, useMemo, useRef, useState } from 'react';
import CsvDownloader from 'react-csv-downloader';

export function Clients() {
  const { hasura } = useContext(ConfigurationContext);
  const { showPrompt, showAlert } = useContext(NotificationsContext);

  const [isEditModalOpened, setIsEditModalOpened] = useState(false);
  const [selectedItem, setSelectedItem] = useState<any>(null);

  const [, setIsTransferring] = useState(false);
  const tableRef = useRef<DataTableRef>(null);
  const clientsRef = useRef<any[]>([]);

  const columns = useMemo<HasuraDataTableColumnDef[]>(
    () => [
      {
        field: 'id',
        headerName: 'ID',
        minWidth: 40,
        width: 60,
      },
      { field: 'firstName', headerName: 'First name', flex: 1 },
      { field: 'lastName', headerName: 'Last name', flex: 1 },
      { field: 'email', headerName: 'E-mail', flex: 1 },
      {
        field: 'subscription',
        headerName: 'Last purchase',
        valueGetter(params) {
          return params?.value?.title;
        },
      },
      {
        field: 'os',
        selector: 'platform',
        headerName: 'OS',
        valueGetter({ row }) {
          return row.platform
            ? `${row.platform.platform.toUpperCase()} v${
                row.platform.platformVersion
              }`
            : null;
        },
      },
      {
        field: 'version',
        selector: 'platform',
        headerName: 'App version',
        valueGetter({ row }) {
          return row.platform ? `v${row.platform.appVersion}` : null;
        },
      },
      {
        field: 'oneSignalUserId',
        headerName: 'PUSH-notifications',
        type: 'boolean',
        valueGetter({ value }) {
          return !!value;
        },
        width: 200,
      },
      { field: 'lastLoggedAt', headerName: 'Last active', type: 'date' },
    ],
    [],
  );

  return (
    <TablePageLayout
      title="Clients"
      actionContent={
        <Stack
          direction="row"
          spacing={2}
          justifyContent="flex-end"
          sx={{ flex: 1 }}
        >
          <Button
            variant="contained"
            startIcon={<AccountArrowRight />}
            onClick={async () => {
              try {
                setIsTransferring(true);

                const values = await showPrompt({
                  title: 'Select client',
                  form: (
                    <>
                      <FormInput
                        name="email"
                        label="Client email"
                        type="email"
                        required
                        autoFocus
                      />
                    </>
                  ),
                  accept: 'Transfer',
                  width: 500,
                });

                // @ts-ignore
                const email = values?.email as string;

                if (!email) {
                  return;
                }

                const [userData] = await hasura.request({
                  type: 'query',
                  selection: 'id subscription firstName lastName email',
                  source: 'user',
                  where: { email: { _ilike: email.trim() } },
                  limit: 1,
                });

                if (!userData) {
                  showAlert('User not found', 'error');
                  return;
                }

                if (!userData.subscription) {
                  showAlert('User has no subscription', 'error');
                  return;
                }

                const periodUnit: 'year' | 'month' =
                  userData.subscription.periodUnit;

                await hasura.request({
                  type: 'mutation',
                  source: 'gift',
                  action: 'insert',
                  items: [
                    {
                      senderFirstName: 'Yves',
                      senderLastName: 'De Wolf',
                      senderEmail: 'yves@sensityves.be',
                      isCompleted: true,
                      productId: periodUnit === 'year' ? '1_jaar' : '1_maand',
                      recipientEmail: userData.email,
                      recipientFirstName: userData.firstName,
                      recipientLastName: userData.lastName,
                      sendToRecipient: true,
                      stripeProductId: 'manual',
                    },
                  ],
                });

                showAlert('Transfer successful', 'success');
              } finally {
                setIsTransferring(false);
              }
            }}
          >
            Transfer subscription
          </Button>
          <CsvDownloader
            datas={async () => {
              const clients = await hasura.request({
                type: 'query',
                source: 'user',
                where: {
                  role: { _eq: 'user' },
                  isVerified: { _eq: true },
                },
                orderBy: [{ id: 'DESC' }],
                selection: ['id', 'firstName', 'lastName', 'email'],
              });

              clientsRef.current = clients;

              return clientsRef.current;
            }}
            filename="Clients.csv"
          >
            <Button variant="contained" startIcon={<FileExcel />}>
              Export CSV
            </Button>
          </CsvDownloader>
        </Stack>
      }
    >
      <DataTable
        id="clients-table"
        ref={tableRef}
        mode="hasura"
        source="user"
        columns={columns}
        disableRemovedFilter
        editable={{
          onEdit: (row) => {
            setSelectedItem(row);
            setIsEditModalOpened(true);
          },
        }}
        selectProps={{
          filter: {
            role: { _eq: 'user' },
            isVerified: { _eq: true },
          },
        }}
        sortBy={{ field: 'id', sort: 'desc' }}
        persistStateMode="query"
        searchFilter={{
          inputProps: {
            placeholder: 'Search by name or email',
          },
          filter: (search) => ({
            _or: search.flatMap((str) => [
              { fullName: { _ilike: `%${str}%` } },
              { email: { _ilike: `%${str}%` } },
            ]),
          }),
        }}
      />
      <FormDialog
        source="user"
        {...(selectedItem && { entityId: selectedItem.id })}
        open={isEditModalOpened}
        onClose={() => setIsEditModalOpened(false)}
        title={!selectedItem ? 'New user' : 'Edit user'}
        maxWidth="md"
        onSubmit={() => {
          tableRef.current?.reload();
        }}
        formProps={{
          defaultValues: { cardDarkTheme: false },
        }}
        formSubmitterProps={{
          mode: 'rest',
          url: () =>
            !selectedItem
              ? { url: '/users', method: 'POST' }
              : { url: `/users/${selectedItem.id}`, method: 'PUT' },
        }}
        formFetcherProps={{
          selector: {
            password: null,
          },
        }}
      >
        <FormInput name="firstName" label="First name" md={4} />
        <FormInput name="lastName" label="Last name" md={4} />
        <FormInput name="email" label="E-mail" type="email" md={4} required />
        <FormInput
          name="password"
          label="Password"
          type="password"
          md={6}
          required={!selectedItem}
          autoComplete="new-password"
          placeholder={selectedItem && 'Enter to change'}
        />
      </FormDialog>
    </TablePageLayout>
  );
}
