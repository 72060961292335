import {
  FileInput,
  FormInput,
  FormPageLayout,
  FormTab,
  FormTabs,
  Grid,
} from '@kirz/mui-admin';
import { Box } from '@mui/material';
import { Translate } from 'mdi-material-ui';
import React from 'react';
import { useParams } from 'react-router-dom';

import { FaqQuestionsTable } from 'components/FaqQuestionsTable';

export function Language() {
  const routeParams = useParams() as Record<string, string>;
  const languageId = parseInt(routeParams.id, 10);

  return (
    <FormPageLayout
      source="language"
      breadcrumbs={[
        {
          text: 'Languages',
          icon: Translate,
          href: '/languages',
        },
        (item) => ({
          text: `${item.name || ''}`,
          copyOnClick: true,
        }),
      ]}
      breadcrumbsDeps={['name']}
      defaultRoute="/languages"
    >
      <FormTabs
        persistStateMode="query"
        tabs={[
          { id: 'main', label: 'Main' },
          { id: 'login', label: 'Login' },
          { id: 'signup', label: 'Signup' },
          { id: 'forgot-password', label: 'Forgot password' },
          { id: 'profile', label: 'Profile' },
          { id: 'subscription', label: 'Subscription' },
          { id: 'feedback', label: 'Feedback' },
          { id: 'challenge', label: 'Challenge' },
          { id: 'errors', label: 'Errors' },
          { id: 'other', label: 'Other' },
        ]}
        tabsWrapperStyle={{
          mx: { xs: 0, md: 0 },
        }}
      >
        <FormTab tab="main">
          <FormInput name="name" label="Name" required md={3} />
          <FormInput name="code" label="Language code" required md={3} />
          <FormInput
            name="mainWelcomeMessage"
            label="Welcome message"
            required
            md={6}
          />
          <FormInput name="goodMorning" label="Good morning" required md={3} />
          <FormInput
            name="goodAfternoon"
            label="Good afternoon"
            required
            md={3}
          />
          <FormInput name="goodEvening" label="Good evening" required md={3} />
          <FormInput name="goodNight" label="Good night" required md={3} />
          <FormInput
            name="meditations"
            label="[Tab] Meditations"
            required
            md={3}
          />
          <FormInput name="downloads" label="[Tab] Downloads" required md={3} />
          <FormInput name="search" label="[Tab] Search" required md={3} />
          <FormInput name="profile" label="[Tab] Profile" required md={3} />

          <FormInput
            name="favouritesTopText"
            label="Favourites top text"
            required
            md={4}
            multiline
            rows={2}
          />
          <FormInput
            name="downloadsTopText"
            label="Downloads top text"
            required
            md={4}
            multiline
            rows={2}
          />
          <FormInput
            name="profileTopText"
            label="Profile top text"
            required
            md={4}
            multiline
            rows={2}
          />
          <FormInput
            name="whatCanWeHelpYouWith"
            label="What can we help you with?"
            required
            md={4}
          />
          <FormInput
            name="yourLastListenedMeditations"
            label="Your last listened meditations"
            required
            md={4}
          />
          <FormInput
            name="mostPopularMeditations"
            label="Most popular meditations"
            required
            md={4}
          />
          <FormInput
            name="newMeditations"
            label="New meditations"
            required
            md={4}
          />
          <FormInput
            name="yourFavouriteMeditations"
            label="Your favourite meditations"
            required
            md={4}
          />

          <FormInput
            name="deleteAudioConfirm"
            label="Do you want to delete this audio?"
            required
            md={4}
          />
          <FormInput
            name="availableLengths"
            label="Available in these lengths:"
            required
            md={4}
          />
          <FormInput name="offlineMode" label="Offline mode" required md={4} />
          <FormInput
            name="searchPlaceholder"
            label="Search placeholder"
            required
            md={4}
          />
          <FormInput name="deelQuote" label="Share quote" required md={4} />
        </FormTab>
        <FormTab tab="login">
          <FormInput
            name="selectYourLanguage"
            label="Select your language"
            required
            md={4}
          />
          <FormInput
            name="pleaseEnterYourEmailAddress"
            label="Please enter your e-mail address"
            required
            md={4}
          />
          <FormInput
            name="emailAddress"
            label="E-mail Address"
            required
            md={4}
          />
          <FormInput
            name="termOfServiceMessage"
            label="Term of service message"
            required
            md={4}
          />
          <FormInput
            name="termsOfService"
            label="Terms of Service"
            required
            md={4}
          />
          <FormInput
            name="privacyPolicy"
            label="Privacy Policy"
            required
            md={4}
          />
          <FormInput
            name="welcomeBackFirstName"
            label="Welcome back [FIRST_NAME]"
            required
            md={4}
          />
          <FormInput
            name="pleaseEnterYourPassword"
            label="Please enter your password"
            required
            md={4}
          />
          <FormInput name="password" label="Password field" required md={4} />
        </FormTab>
        <FormTab tab="signup">
          <FormInput
            name="signupWelcomeMessage"
            label="Welcome [EMAIL]"
            required
            md={4}
          />
          <FormInput name="wrongEmail" label="Wrong e-mail?" required md={4} />
          <FormInput
            name="firstNameField"
            label="First name field"
            required
            md={4}
          />
          <FormInput
            name="lastNameField"
            label="Last name field"
            required
            md={4}
          />
          <FormInput
            name="pleaseEnterThePasswordYouWouldLikeToUse"
            label="Please enter the password you would like to use"
            required
            md={4}
          />
          <FormInput
            name="passwordField"
            label="Password field"
            required
            md={4}
          />
          <FormInput
            name="emailVerificationInstruction"
            label="E-mail verification instruction"
            required
            md={4}
          />
          <FormInput name="resendCode" label="Resend code" required md={4} />
          <FormInput
            name="codeSentSuccessfully"
            label="Code sent successfully"
            required
            md={4}
          />
          <FormInput
            name="signupInstructions"
            label="Please enter details in the fields below to finish registration"
            required
            md={4}
          />
          <FormInput
            name="validationResendCodeSuccess"
            label="New code has been sent"
            required
            md={4}
          />
        </FormTab>
        <FormTab tab="forgot-password">
          <FormInput
            name="forgotPassword"
            label="Forgot password?"
            required
            md={4}
          />
          <FormInput
            name="forgotPasswordEmailVerification"
            label="Forgot password email verification"
            required
            md={4}
          />
          <FormInput
            name="newPasswordText"
            label="New password text"
            required
            md={4}
          />
          <FormInput
            name="newPasswordField"
            label="New password field"
            required
            md={4}
          />
        </FormTab>
        <FormTab tab="profile">
          <FormInput name="profileAboutUs" label="About us" required md={4} />
          <FormInput name="profileFaq" label="FAQ" required md={4} />
          <FormInput
            name="profileChangePassword"
            label="Change password"
            required
            md={4}
          />
          <FormInput
            name="profileMyProfile"
            label="My profile"
            required
            md={4}
          />
          <FormInput
            name="profileSubscription"
            label="Subscription"
            required
            md={4}
          />
          <FormInput
            name="profilePrivacyPolicy"
            label="Privacy policy"
            required
            md={4}
          />
          <FormInput
            name="profileTermsAndConditions"
            label="Terms and conditions"
            required
            md={4}
          />
          <FormInput name="profileLogout" label="Logout" required md={4} />
          <FormInput name="oldPassword" label="Old password" required md={4} />
          <FormInput name="newPassword" label="New password" required md={4} />
          <FormInput
            name="confirmPassword"
            label="Confirm new password"
            required
            md={4}
          />
          <FormInput
            name="deleteAccountConfirm"
            label="Do you want to delete your account?"
            required
            md={4}
          />
          <FormInput
            name="deleteAccount"
            label="Delete account"
            required
            md={4}
          />
          <Grid container md={12}>
            <Grid md={6} sx={{ pt: 2 }}>
              <FaqQuestionsTable
                id="faq-questions-table"
                languageId={languageId}
                sx={{
                  minHeight: 400,
                  borderColor: 'transparent',
                }}
              />
            </Grid>
            <Grid md={6} sx={{ pt: 2 }}>
              <FormInput
                name="aboutUsMarkdown"
                label="[Markdown] About us content"
                required
                rows={18}
                multiline
                grid={false}
                helperText={
                  <Box>
                    You can use this{' '}
                    <a href="https://stackedit.io/app#" target="_blank">
                      markdown editor
                    </a>
                  </Box>
                }
              />
            </Grid>
          </Grid>
        </FormTab>
        <FormTab tab="subscription">
          <FormInput
            name="subscriptionTitle"
            label="Subscription title [for Profile]"
            required
            md={4}
          />
          <FormInput
            name="subscriptionTitleAfterMeditationClick"
            label="Subscription title [for locked meditation]"
            required
            md={4}
          />
          <FormInput
            name="subscriptionTopText"
            label="Subscription top text"
            required
            md={4}
          />
          <FormInput
            name="subscriptionPopular"
            label="Popular choice!"
            required
            md={4}
          />
          <FormInput
            name="subscribeNow"
            label="Subscribe now"
            required
            md={4}
          />
          <FormInput
            name="monthlySubscription"
            label="Monthly subscription"
            required
            md={4}
          />
          <FormInput
            name="yearlySubscription"
            label="Yearly subscription"
            required
            md={4}
          />
          <FormInput
            name="lifetimePurchase"
            label="Liftime purchase"
            required
            md={4}
          />
          <FormInput name="day" label="Trial period: days" required md={4} />
          <FormInput name="day" label="Trial period: weeks" required md={4} />
          <FormInput
            name="restorePurchases"
            label="Restore purchases"
            required
            md={4}
          />
          <FormInput
            name="nothingToRestore"
            label="Nothing to restore"
            required
            md={4}
          />
          <FormInput
            name="premiumRestored"
            label="Premium access restored"
            required
            md={4}
          />

          <FormInput name="tryForFree" label="Try for free" required md={4} />
        </FormTab>
        <FormTab tab="errors">
          <FormInput name="error" label="Error" required md={4} />
          <FormInput
            name="wrongEmailFormatError"
            label="Wrong email format"
            required
            md={4}
          />
          <FormInput
            name="wrongValidationCode"
            label="Wrong validation code"
            required
            md={4}
          />
          <FormInput
            name="wrongPassword"
            label="Wrong password"
            required
            md={4}
          />
          <FormInput
            name="errorPasswordTooWeak"
            label="Password is too weak"
            required
            md={4}
          />
          <FormInput
            name="errorPasswordsMismatch"
            label="Password mismatch"
            required
            md={4}
          />
          <FormInput
            name="errorAudioIsNotDownloadable"
            label="Audio is not downloadable"
            required
            md={4}
          />
          <FormInput
            name="downloadNotAllowedInFreeMode"
            label="Audio is not downloadable (in a free mode)"
            required
            md={4}
          />
        </FormTab>
        <FormTab tab="feedback">
          <FormInput
            name="feedbackTopText"
            label="Top text"
            required
            md={3}
            multiline
            rows={3}
          />
          <FormInput name="feedbackNegative" label="Negative" required md={3} />
          <FormInput name="feedbackNeutral" label="Neutral" required md={3} />
          <FormInput name="feedbackPositive" label="Positive" required md={3} />
          <FormInput
            name="feedbackTextinputPlaceholder"
            label="Text area placeholder"
            required
            md={3}
          />
          <FormInput
            name="feedbackSubmitButton"
            label="Submit button text"
            required
            md={3}
          />
          <FormInput
            name="feedbackSuccessAlert"
            label="Success alert text"
            required
            md={3}
          />
        </FormTab>
        <FormTab tab="challenge">
          <FormInput
            name="challengeButtonText"
            label="Button text"
            required
            md={3}
          />
          <FormInput
            name="challengeHideModalTitle"
            label="Hide Modal title"
            required
            md={3}
          />
          <FormInput
            name="challengeHideModalText"
            label="Hide Modal text"
            required
            md={3}
          />
          <FormInput
            name="challengeHideModalCancel"
            label="Hide Modal cancel"
            required
            md={3}
          />
          <FormInput
            name="challengeHideModalConfirm"
            label="Hide Modal confirm"
            required
            md={3}
          />
          <FormInput
            name="challengeJoinModalTitle"
            label="Join Modal title"
            required
            md={3}
          />
          <FormInput
            name="challengeJoinModalText"
            label="Join Modal text"
            required
            md={3}
          />
          <FormInput
            name="challengeJoinModalCancel"
            label="Join Modal cancel"
            required
            md={3}
          />
          <FormInput
            name="challengeJoinModalConfirm"
            label="Join Modal confirm"
            required
            md={3}
          />
        </FormTab>
        <FormTab tab="other">
          <FormInput name="saved" label="Saved" required md={3} />
          <FormInput
            name="minutesShort"
            label="Minutes (short)"
            required
            md={3}
          />
          <FormInput name="save" label="Save" required md={3} />
          <FormInput name="labelNew" label="New" required md={3} />
          <FormInput name="all" label="All" required md={3} />
          <FormInput name="yes" label="Yes" required md={3} />
          <FormInput name="no" label="No" required md={3} />
          <FormInput name="exit" label="Exit" required md={3} />
          {/* <FormInput
            name="downloadFile"
            label="Download file"
            required
            md={3}
          />
          <FormInput
            name="removeFromDownloads"
            label="Remove from downloads"
            required
            md={3}
          />
          <FormInput
            name="makeFavourite"
            label="Make favourite"
            required
            md={3}
          />
          <FormInput
            name="removeFromFavourites"
            label="Remove from favourites"
            required
            md={3}
          /> */}
          <FormInput
            name="sendFeedback"
            label="Send a feedback"
            required
            md={3}
          />
          <FormInput
            name="privacyPolicyUrl"
            label="[URL] Privacy policy"
            required
            md={6}
          />
          <FormInput
            name="termsOfUseUrl"
            label="[URL] Terms of use"
            required
            md={6}
          />
          <FormInput
            name="verificationEmailSubject"
            label="Verification email subject"
            required
            md={6}
          />
          <FormInput
            name="passwordRecoveryEmailSubject"
            label="Password recovery email subject"
            required
            md={6}
          />
          <FormInput
            name="playMusicAfterMeditation"
            label="Play music after meditation?"
            required
            md={6}
          />
          <FormInput
            name="musicAfterMediationIsNowOn"
            label="Music after mediation is now on"
            required
            md={6}
          />
          <FormInput
            name="verificationEmailContent"
            label="[HTML] Verification email content"
            required
            multiline
            rows={3}
            md={6}
          />
          <FormInput
            name="passwordRecoveryEmailContent"
            label="[HTML] Password recovery email content"
            required
            multiline
            rows={3}
            md={6}
          />
          <FileInput
            name="premiumFallbackAudioId"
            label="Premium fallback audio"
            required
            md={6}
            dropzoneProps={{
              accept: {
                'audio/wav': ['.wav'],
                'audio/aac': ['.aac'],
                'audio/mpeg': ['.mp3'],
              },
            }}
          />
        </FormTab>
      </FormTabs>
    </FormPageLayout>
  );
}
